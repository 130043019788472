import React, { FC } from 'react';
import styled from 'styled-components';
import { useSignup } from '@/features/signup/signup_form/useSignup';
import { FloatingInput } from '@/components/floating-label-text-input';
import { BigButton } from '@/components/big-button';
import { SignupLayout } from '@/features/signup/shared/layout';
import { SignupBoxForm } from '@/features/signup/shared/box';
import {
  SignupErrorMessage,
  SignupLogo,
  SignupSubtitle,
  SignupTitle,
} from '@/features/signup/shared/components';
import { VSpace } from '@/components/spacing';

const Terms = styled(SignupSubtitle)`
  && {
    font-size: 1.4rem;

    a {
      text-decoration: none;
      color: #1c86da;
    }
  }
`;

export const SignupForm: FC = () => {
  const { register, onSubmit, email, errors, error, loading, domain } =
    useSignup();
  return (
    <SignupLayout>
      <SignupBoxForm onSubmit={onSubmit}>
        <SignupLogo />
        <VSpace value={2} />
        <SignupTitle>Create an account</SignupTitle>
        <VSpace value={1} />
        <div>
          <FloatingInput err={!!errors.name}>
            <input
              id="input__name"
              data-testid="name-input"
              placeholder="Full Name"
              defaultValue=""
              type="text"
              disabled={loading}
              {...register(`name`, { required: true })}
            />
            <label htmlFor="input__name" data-content="Full Name" />
            {errors.name && <span>This field is required</span>}
          </FloatingInput>
          <FloatingInput err={!!errors.email}>
            <input
              data-testid="email-input"
              placeholder="Your Email"
              defaultValue={email}
              type="email"
              id="input__email"
              name="email"
              disabled={loading}
              {...register(`email`, { required: true })}
            />
            <label htmlFor="input__email" data-content="Your Email" />
            {errors.email && <span>This field is required</span>}
          </FloatingInput>
          <FloatingInput>
            <input
              id="input__domain"
              {...register(`domain`)}
              value={domain}
              disabled
            />
            <label htmlFor="input__domain" data-content="Shopify Domain" />
          </FloatingInput>
        </div>
        <BigButton
          border="2px solid black"
          noTransform
          size="xl"
          fillWidth
          data-testid="submit-btn"
          type="submit"
          disabled={loading}
        >
          Continue
        </BigButton>
        <VSpace value={1} />
        <Terms>
          By proceeding, you agree to the{` `}
          <a href="/public/privacy-policy" target="_blank">
            terms and conditions
          </a>
        </Terms>
        {error && (
          <SignupErrorMessage style={{ marginTop: `2rem` }}>
            oops.. something went wrong
          </SignupErrorMessage>
        )}
      </SignupBoxForm>
    </SignupLayout>
  );
};
