import { useForm } from 'react-hook-form';
import { CachePolicies, useFetch } from 'use-http';
import { StringParam, useQueryParam } from 'use-query-params';
import { navigate } from 'gatsby';
import { routes } from '@/webapi/routes';
import {
  SignupRequest,
  SignupResponse,
} from '@/features/signup/signup_form/types';
import { Pages } from '@/webapi/pages';

export function useSignup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  const [storeAlias] = useQueryParam(`s`, StringParam);
  const [token] = useQueryParam(`t`, StringParam);
  const [email] = useQueryParam(`e`, StringParam);
  const [domain] = useQueryParam(`d`, StringParam);
  const onSubmit = async ({ email, name }: { email: string; name: string }) => {
    const splitOnSpace = name.split(` `);
    const first = splitOnSpace?.[0];
    const last = splitOnSpace?.[1] || ``;
    const { sessionId } = (await post(routes.signup(), {
      email,
      firstName: first,
      lastName: last,
      storeAlias,
      token,
    } as SignupRequest)) as SignupResponse;
    if (sessionId) {
      await navigate(Pages.AWAIT_EMAIL, {
        state: { email, sessionId, type: `signup` },
      });
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    email,
    onSubmit: handleSubmit(onSubmit),
    error,
    loading,
    domain,
  };
}
